<template>
  <section class="kaspi">
    <div class="container">
      <h1>Congratulation your payment is successful!</h1>
      <!-- <form action="https://kaspi.kz/online" method="post" id="kaspikz-form" class="payment-form">
        <input type="hidden" name="TranId" value="212695" />
        <input type="hidden" name="OrderId" value="212695" />
        <input type="hidden" name="Amount" value="1200000" />
        <input type="hidden" name="Service" value="test" />
        <input
          type="hidden"
          name="returnUrl"
          value="https://test.kz/ru/order/kaspi-success?id=1234567"
        />
        <input type="hidden" name="Signature" value=" " />
        <button class="btn btn-danger" type="submit">
          Оплатить
        </button>
      </form> -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userInfo: {
        order_Id: "",
        accountId: "",
        amount: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getOrder"]),
  },
  created() {
    console.log(this.getOrder);
    this.userInfo = this.getOrder;
    // this.$root.$on("userOrder", (data) => {
    //   this.userInfo = data
    // })
  },
};
</script>

<style scoped>
.container {
  max-width: 1068px;
  margin: 0 auto;
  padding: 0 20px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 40px;
  text-align: center;
  padding-top: 10rem;
  padding-bottom: 20rem;
}
</style>